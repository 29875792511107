import Select, { components } from 'react-select';
import React, { useCallback, useEffect, useState } from "react";
import { EvaluationDate } from '../../DatePicker';
import ReactSelect from "../../../common/ReactSelect";
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { GET_SHOP_LIST,GET_SHOP_REGION_LIST,GET_EVALUATION_DATA,SAVE_EVALUATION_DATA,UPLOAD_EVAL_FILE,GET_REPAIR_DOC} from "../../../services/claimEvaluation.gql";
import { ACCREDITED, ROLE_FEATURES, STATUS_SUB_STATUS } from '@src/config/constant';
import { executeGraphQLMutation,executeGraphQLQuery } from "../../../common/executeGraphQLQuery";

import FileUpload from '../../../common/FileUpload';
import { validateEvaluationFields } from '@src/common/FunctionList';
import { fetchClaimProcessingData,updateClaimDetailsInStore,updateClaimHistory, updateClaimRibbonData} from '@src/store/slices/claim';
import { executeUpload } from '@src/common/executeGraphQLQuery';
import { print } from 'graphql'
import CurrencyInputField from "@src/common/CurrencyInputField";
import * as HELPER from '../../../config/helper';
import { getRegionListing } from '@store/action/allAction';




export default function EvaluationReceived(props: any) {
    const [formFields, setFormFields] = useState<any>({claim_id:''})
    const [repairShop, setRepairShop] = useState<any>([]);
    const [repairShopRegion, setRepairShopRegion] = useState<any>([])
    const [detailEval, setDetailEval] = useState<any>([])
    const [effectivityDate, setEffectivityDate] = useState<any>()
    const [evaluationLetter, setEvaluationLetter] = useState<boolean>(false)
    const [evaluationHeading, setEvaluationHeading] = useState<string>();
    const [errorMsg, setErrorMsg] = useState<any>({})
    const [refresh, setRefresh] = useState<boolean>(false)
    const [showContinue, setShowContinue] = useState<boolean>(false)
    const [uploadReset, setUploadReset] = useState<any>(0); 
    const [BlankFlage, setBlankFlage] = useState<any>(0); 
    let [emptyErr, setEmptyErr] = useState<boolean>(false)
    let [uploadRowId, setUploadRowId] = useState<any>()
    const [imageName, setImageName] = useState<any>(); 
    const [fieldDisable, setFieldDisable] = useState<any>(false); 
    const [addBtnRepairStatus, setAddBtnRepairStatus] = useState<boolean>(false); 
    const [addBtnEvalStatus, setAddBtnEvalStatus] = useState<boolean>(false); 
    const keysToRemove = ["__typename"];
    const [showAddRepairEstimate, setShowRepairEstimate] = useState(true)
    const [showAddEvaluation, setShowEvaluation] = useState(true)
    const [regionListing, setRegionListing] = useState<Array<any>>([])

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedRouteType = searchParams.get('type');
    const client = useApolloClient();
    const dispatch: any = useDispatch()
    const claimState: any = useSelector((state: any) => state.claim);
    const commonState: any = useSelector((state: any) => state.common);
    let [showEstimateFile, setShowEstimateFile] = useState<string>('')
    const claimId = location?.pathname?.split('/')?.[2] || null

    const getRepairShopList = async (partner_id:number) => {
        props.setLoader(true);
        executeGraphQLQuery(GET_SHOP_LIST(partner_id), client).then((response:any) => { 
            props.setLoader(false);
            if(response && response.data && response.data.getListRepairShop && response.data.getListRepairShop.status===200){
                let data:any=response.data.getListRepairShop.data;
                setRepairShop([...data]);
            }
        }).catch((error:string) => {
            toast.error(error);
            props.setLoader(false);
        });
    }

    const getRepairShopRegionList = async (shop_id:any,partner_id:number) => {
        props.setLoader(true);        
        executeGraphQLQuery(GET_SHOP_REGION_LIST(shop_id,partner_id), client).then((response:any) => { 
            props.setLoader(false);
            if(response && response.data && response.data.getListShopRegion && response.data.getListShopRegion.status===200){
                let data:any=response.data.getListShopRegion.data;
                setRepairShopRegion([...data]);
            }
        }).catch((error:string) => {
            toast.error(error);
            props.setLoader(false);
        });
    }

    const onValueSelect = (field: string, value: any) => {
        let evObj = JSON.parse(JSON.stringify(formFields));
        if (field === "cash_settlement") {
            evObj[field] = +value.target.value
        } else {
            if (field === "shop_id") {
                // let partner_id=claimState?.claimDetails?.partner_id
                // getRepairShopRegionList(value.value,partner_id)
                delete evObj['is_shop_accredited'];
                evObj['region_id'] = '';
            } else if (field === "region_id") {                
                evObj["is_shop_accredited"] = repairShopRegion.find((e: any) =>  e?.shop_id?.includes(evObj['shop_id']) && +e.value === +value.value)?.status || 0
            }
            evObj[field] = value.value
        }
        setFormFields({ ...evObj });
        errorMsg[field] = '';
        setErrorMsg({ ...errorMsg });
    }

    const handleDateAmountChange = (field:any,rowId:number, value: any) => { 
        let detail_eval=[...detailEval];
        if(detail_eval.length){
            let index = detail_eval.findIndex((el:any)=>el.rowId===+rowId);
            detail_eval[index][field]=value;
           
            errorMsg[`${field}_${rowId}`] = '';
            setErrorMsg({ ...errorMsg });
            setDetailEval(detail_eval);
        }
    }

    const getEvalData = async(partner_id:any=null) => {
        props.setLoader(true);
        executeGraphQLQuery(GET_EVALUATION_DATA(claimId), client).then((response:any) => { 
            props.setLoader(false);
            if(response && response.data && response.data.getEvaluationData && response.data.getEvaluationData.status===200){
                const data:any=response.data.getEvaluationData.data;
                if(data!=null){
                    delete data["__typename"];
                    let {eval_details,...rest}=data;
                    setFormFields({...rest});
                    eval_details=eval_details.map(({ [keysToRemove[0]]: _, ...rest })=>rest);
                   
                    getRepairShopRegionList(null,partner_id);
                    addDetailEval(eval_details);
                    setAddBtnRepairStatus(true); 
                    setAddBtnEvalStatus(true); 
                }else{
                    addDetailEval([]);
                }
            }
            
        }).catch((error:string) => {
            toast.error(error);
            props.setLoader(false);
        });
    }

    const addDetailEval=async(eval_data:any)=>{
        if(eval_data.length>0){
            setDetailEval([...eval_data]);
        }else{
            let filedata:any=await getRepairDoc(5);
            let defaultdata=[{ev_datetime:null,ev_amount:null,img_name:filedata?.imgName || null,url:filedata?.file || null,ev_type:1,rowId:1},{ev_datetime:null,ev_amount:null,img_name:null,url:null,ev_type:2,rowId:2}];
            setDetailEval(defaultdata);
            
        }
    }

    const addEvaluationSlab = (type:number) => {
        let row=detailEval.length+1;

        let defaultObj={ev_datetime:null,ev_amount:null,img_name:null,url:null,ev_type:type,rowId:row};
        let filterDetailEval=detailEval.filter((el:any)=>el.ev_type===type);
        if(filterDetailEval.length<2 && filterDetailEval[0]?.id){
            setDetailEval([...detailEval,defaultObj]);
        }else{
            let message=(type===1)?`You can't add more than two repair estimate details`:`You can't add more than two repair evaluation details`;
            toast.error(message);
        }  
    };

    const showEvaluationLetterPopup = (type:number,rowId:number) => {
        let heading=(type===1)?`Attach Repair Estimate`:`Attach Evaluation Letter`;
        let imgName=(type===1)?`estimation`:`evaluation`
        setImageName(imgName);
        setEvaluationHeading(heading);
        setUploadRowId(rowId);
        setEvaluationLetter(true);
        document.body.classList.add("overflow-hidden");
    }

    const hideEvaluationLetterPopup = () => {
        setEvaluationLetter(false);
        setImageName('');
        setEvaluationHeading('');
        setUploadRowId('');
        setUploadReset((prev: any) => prev + 1);
        document.body.classList.remove("overflow-hidden");
    }

    /*
    *Remove Image
    */
    const removeImage=async(type:number,rowId:number)=>{
        let detail_eval=[...detailEval];
        if(detail_eval.length){
            let index = detail_eval.findIndex((el:any)=> Number(el.rowId) === +rowId && Number(el.ev_type)=== +type);
           
            detail_eval[index]["img_name"]=null;
            detail_eval[index]["url"]=null;
            setDetailEval(detail_eval);
            setShowEstimateFile('');
        }
    }

    const uploadImage=async(file:any)=>{
        if(file){            
            props.setLoader(true);
            let fileType = file.type.split("/")[1];
            let filesArray: any = []
            filesArray.push(file);
            const formData = new FormData();
            const operations = JSON.stringify({
                query: print(UPLOAD_EVAL_FILE()),
                variables: {
                    file:filesArray,
                    claim_id: claimId
                }
            });
    
            const map: any = {};
            filesArray.forEach((_: any, index: any) => {
                map[index] = [`variables.file.${index}`];
            });
    
            formData.append('operations', operations);
            formData.append('map', JSON.stringify(map));
    
            filesArray.forEach((file: any, index: any) => {
                formData.append(index.toString(), file);
            });

            try {
                const result = await executeUpload(formData);
                let { status, data } = result?.data?.uploadEvalFile;
                if (status && status === 200) {
                    let detail_eval=[...detailEval];
                    if(detail_eval.length){
                        let index = detail_eval.findIndex((el:any)=>el.rowId===+uploadRowId);
                        detail_eval[index]["url"]=data;
                        detail_eval[index]["img_name"]=`${imageName}.${fileType}`;
                        setDetailEval(detail_eval);
                        setUploadRowId('');
                        hideEvaluationLetterPopup();
                        setUploadReset('');
                        setBlankFlage(BlankFlage+1);
                        setRefresh(!refresh)
                        errorMsg[`img_name_${uploadRowId}`] = '';
                        setErrorMsg({ ...errorMsg }); 
                    }else{
                        setUploadReset('');
                        setBlankFlage(BlankFlage+1);
                        setRefresh(!refresh)
                    }
                }
               
            } catch {
    
            } finally {
                props.setLoader(false);
            }
        }

    }
    
    const validateFields = () => {
        let errorData:any=validateEvaluationFields(formFields,detailEval);
        setErrorMsg(errorData.error_msg);
        setRefresh(!refresh)
        return errorData.error;
    }

    const handleSave = async () => {
        props.setLoader(true)
        const isValid = validateFields()
        if (isValid) {
            props.setLoader(false)
            return false
        }
        let detaileval=[...detailEval];
            detaileval=detaileval.map((el:any)=>({...el,ev_amount:HELPER.removeCurrencyFormatting(el.ev_amount)}));
        let evalFields={
            saveEvalInput:{
                ...formFields,
                eval_details:detaileval
            }
        }
        executeGraphQLMutation(SAVE_EVALUATION_DATA,evalFields, client).then((response:any) => { 
            props.setLoader(false);
            let { status, data,message } = response?.data?.save_evaluation;
            if(status && status===200){
                let updatedRibbonData = claimState?.ribbonData?.map((e: any) => {
                    if (e.key === 'status') {
                        return { ...e, value: data.status_id }
                    }
                    return e
                })
                let updatedHistory = claimState?.claimHistory?.includes(data.status_id) ? [] : [...claimState?.claimHistory, data.status_id]

                dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, status_id:data.status_id }))
                dispatch(updateClaimRibbonData(updatedRibbonData)) //update claim status in ribbon
                if (updatedHistory.length) dispatch(updateClaimHistory(updatedHistory)) //update claim status history
                setSearchParams(`?${new URLSearchParams({ type: 'loa-received' })}`)
                toast.success(message);
            }
           
        }).catch((error:any) => {
            props.setLoader(false);
            toast.error(error);
        });
    }

    const fetchprocessData = async()=>{
        try {
            const result: any = await fetchClaimProcessingData({ type: 'applicationDetails', variables: { claim_id: claimId, api_type: 'applicationDetails' } });
            dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, ...result?.data, loading: false }));
            if(result.status===200){
                return result.data;
            }
        } catch {
            toast.error("Error in fetching data")
        }
    }

    const getRepairDoc = async(doc_id:number) => {
        try{
            props.setLoader(true);
            const response = await executeGraphQLQuery(GET_REPAIR_DOC(claimId, doc_id), client);
            props.setLoader(false);
            if(response && response.data && response.data.getRepairDocument && response.data.getRepairDocument.status===200){
                let data:any=response.data.getRepairDocument.data;
                if(Object.keys(data).length){
                    setShowEstimateFile(data.url)
                    let file:any=data.doc_path;
                    let filetype:any=data.doc_path.split('.');
                    let imgName:string=`estimation.${filetype[1]}`;
                    return {imgName,file};
                }  
            }    
        }catch(error:any){
            toast.error(error);
            props.setLoader(false);
        }
          
       
    }
    const showImage=async(url:string,name:string)=>{
        const link = document.createElement('a');
        link.href = url;
        link.target="_blank";
        link.download = name; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const callApi=async()=>{
        let partner_id:any;
        let status_id:any;
        let disableStatus:any;
        
        let evObj=JSON.parse(JSON.stringify(formFields));
        if(claimState && claimState.claimDetails && Object.keys(claimState.claimDetails).length
        ){  
            partner_id=claimState.claimDetails.partner_id;
            status_id=claimState.claimDetails.status_id;
            evObj.claim_id=claimState.claimDetails.id;
            setEffectivityDate(claimState.claimDetails.effectivity_date);
           
        }else{
            let claimData=await fetchprocessData();
            if(Object.keys(claimData).length){
                partner_id=claimData.partner_id;
                status_id=claimData.status_id;
                evObj.claim_id=claimData.id;
                setEffectivityDate(claimData.effectivity_date);
            }
        }
        
        disableStatus=(status_id>=6)?true:false;
        setFieldDisable(disableStatus);
        if ([3,5].includes(status_id)) setShowContinue(true)
            else setShowContinue(false)

        setFormFields({...evObj});
        getRepairShopList(partner_id);
        
        if(status_id>3){            
            getEvalData(partner_id);
            
        }else{
            getRepairShopRegionList(null, partner_id)
            await addDetailEval([]);
        }
       
    }
    useEffect(() => {
        if (!repairShop.length) {
            callApi();
        }
    }, [0]);

    useEffect(() => {
        if (detailEval?.filter((e: any) => e.ev_type === 1)?.length === 2) {
            setShowRepairEstimate(false)
        } else setShowRepairEstimate(true)

        if (detailEval?.filter((e: any) => e.ev_type === 2)?.length === 2) {
            setShowEvaluation(false)
        } else setShowEvaluation(true)

        //eslint-disable-next-line
    }, [detailEval])

    useEffect(() => {        
        if (Object.keys(commonState?.regionList).length) {            
            setRegionListing(commonState.regionList.data)
        }
        //eslint-disable-next-line
    }, [commonState.regionList])

    return (
        <div className="form-tab-right-panel">

            <div className="lead-detail-from-outer">
                <div className="lead-form-heading">
                    <h2>Evaluation Received</h2>
                </div>
                <div className="lead-form-filed p-lg-t">
                    <div className="row">
                        <fieldset className="from-group radio-type-txt d-flex col-md-12 radio-btn flex-wrap">
                            <label className="form-label">Is it a Cash Settlement? *</label>
                            <div className="custom-control custom-checkbox">
                                <input 
                                id="cash_settlement_1" 
                                type="radio" 
                                className="custom-control-input" name="cash_settlement" 
                                value="1"
                                checked={formFields && formFields["cash_settlement"] === 1}
                                disabled={fieldDisable}
                                onChange={(e: any) => onValueSelect("cash_settlement", e)} />
                                <label className="custom-control-label">Yes </label>
                            </div>
                            <div className=" custom-control custom-checkbox m-lg-l">
                                <input 
                                id="Company" 
                                type="radio" 
                                className="custom-control-input" name="cash_settlement" 
                                value="0"
                                checked={formFields && formFields["cash_settlement"] === 0}
                                disabled={fieldDisable}
                                onChange={(e: any) => onValueSelect("cash_settlement", e)} />
                                <label className="custom-control-label">No</label>
                            </div>
                            {errorMsg?.cash_settlement ? <span className="error-txt d-block w-100">{errorMsg.cash_settlement}</span> : null}

                        </fieldset>
                        <ReactSelect
                            options={repairShop}
                            value={formFields && (formFields["shop_id"] || formFields["shop_id"] === 0) && repairShop?.filter((v: any) => +v.value === +formFields?.shop_id)}
                            placeholder="Select Repair Shop *"
                            fieldKey=""
                            fieldItemName="shop_id"
                            isDisabled={fieldDisable}
                            fieldSetClassName={"col-md-4"}
                            errorField={errorMsg?.shop_id}
                            handleChange={(e:any) => onValueSelect("shop_id", e)}
                        />

                        <ReactSelect
                            options={regionListing}
                            value={formFields && formFields["region_id"] && regionListing?.filter((v: any) => +v.value === +formFields?.region_id)}
                            placeholder="Repair Shop Region *"
                            fieldKey=""
                            fieldItemName="region_id"
                            isDisabled={fieldDisable}
                            fieldSetClassName={"col-md-4"}
                            errorField={errorMsg?.region_id}
                            handleChange={(e:any) => onValueSelect("region_id", e)}
                        />
                        <ReactSelect
                            options={ACCREDITED}
                            value={formFields && (formFields["is_shop_accredited"] || formFields["is_shop_accredited"] === 0) && ACCREDITED?.filter((v: any) => +v.value === +formFields?.is_shop_accredited)}
                            placeholder="Is the Shop Accredited? *"
                            fieldKey=""
                            fieldItemName="is_shop_accredited"
                            isDisabled={true}
                            errorField={errorMsg?.is_shop_accredited}
                            fieldSetClassName={"col-md-4"}
                        />
                        
                    </div>

                    <div className='estimate-detail-sec'>
                        <h3>Repair Estimate Details</h3>
                        <div>
                            {
                             detailEval.length>0 && detailEval.filter((el:any)=>el.ev_type===1).map((el:any,index:number)=>
                                <div className='row' key={index}>
                                    <fieldset className={"form-filed date-picker-detail col-md-5"} key={index}>
                                        <div key={index} className={"material animation-effect" + (el.ev_datetime ? ' active-label' : '')}>
                                      
                                            <EvaluationDate
                                            onChange={(e: Date) => handleDateAmountChange("ev_datetime",el.rowId, e)}
                                            placeholder="Date and Time" 
                                            startDate={(el.ev_datetime)?new Date(el.ev_datetime):null}
                                            minDate={new Date(effectivityDate)}
                                            maxDate={null}
                                            disabled={(el.id)?true:false}
                                            />
                                           
                                        </div>
                                    </fieldset>

                                    <fieldset className="form-filed col-md-4">
                                        <div className="material">
                                            <CurrencyInputField
                                                inputProps={{
                                                id: "ev_amount",
                                                type: "text",
                                                name: "ev_amount",
                                                placeholder: " ",
                                                value: el.ev_amount || '',
                                                className: "form-input",
                                                disabled:(el.id)?true:false
                                                }}
                                                
                                                onChange={(event:any) => { handleDateAmountChange("ev_amount",el.rowId, event.target.value) }}
                                            />
                                            
                                            <label data-label="Enter Estimated Amount *" className="form-label"></label>

                                            {errorMsg && errorMsg['ev_amount_'+el.rowId] ? <span className="error-txt">{errorMsg['ev_amount_'+el.rowId]}</span> : null}
                                           
                                        </div>
                                    </fieldset>

                                    <fieldset className="col-md-3">
                                        {
                                            (el.img_name)?
                                                (el.id)?
                                                <div className='attech-repair-doc attech-doc-view' onClick={()=>{showImage(el.url,el.img_name)}}>
                                                    <p>{el.img_name}</p>
                                                    
                                                </div>: 
                                                <div className='attech-repair-doc attech-doc-view'>
                                                    <i className='ic-attach_file'onClick={()=>{showEvaluationLetterPopup(el.ev_type,el.rowId)}} ></i>
                                                    {
                                                      (el.rowId===1 && showEstimateFile)? <p onClick={()=>{showImage(showEstimateFile,el.img_name)}}>{el.img_name}</p>:<p>{el.img_name}</p>
                                                    }
                                                    <i className='ic-cancel' onClick={()=>{removeImage(el.ev_type,el.rowId)}}></i>
                                                </div>: 
                                            <button className='btn-line' onClick={()=>{showEvaluationLetterPopup(el.ev_type,el.rowId)}}>
                                                <i className='ic-attach_file'></i>
                                            Attach Docs
                                            </button>
                                        }
                                        {errorMsg && errorMsg['img_name_'+el.rowId] ? <span className="error-txt">{errorMsg['img_name_'+el.rowId]}</span> : null}
                                    </fieldset>
                                </div>
                                ) 
                            }
                            {
                                showAddRepairEstimate
                                    ?
                                    <fieldset className={(addBtnRepairStatus) ? `col-md-4 display-block` : `col-md-4 display-none`}>
                                        {
                                            (showContinue) ?
                                                <div className='add-repait-doc-txt' onClick={(e: any) => { addEvaluationSlab(1) }}>
                                                    <i className='ic-add'></i>
                                                    Add Updated Repair Estimate
                                                </div>
                                                : undefined
                                        }

                                    </fieldset>
                                    : undefined
                            }
                        </div>
                    </div>


                    <div className='estimate-detail-sec'>
                        <h3>Approved Evaluation Details</h3>
                        <div>
                            {
                               detailEval.length>0 && detailEval.filter((el:any)=>el.ev_type===2).map((el:any,index:number)=>
                                    <div className='row' key={index}>
                                        <fieldset className={"form-filed date-picker-detail col-md-5"} key={index}>
                                            <div key={index} className={"material animation-effect" + (el.ev_datetime ? ' active-label' : '')}>
                                                <EvaluationDate
                                                onChange={(e: Date) => handleDateAmountChange("ev_datetime",el.rowId, e)}
                                                placeholder="Date and Time *" 
                                                startDate={(el.ev_datetime)?new Date(el.ev_datetime):null}
                                                minDate={new Date(effectivityDate)}
                                                maxDate={null}
                                                disabled={(el.id)?true:false}
                                                />
                                                {errorMsg && errorMsg['ev_datetime_'+el.rowId]? <span className="error-txt">{errorMsg['ev_datetime_'+el.rowId]}</span> : null}
                                            </div>
                                        </fieldset>
                                        <fieldset className="form-filed col-md-4">
                                            <div className="material">
                                                <CurrencyInputField
                                                    inputProps={{
                                                    id: "ev_amount",
                                                    type: "text",
                                                    name: "ev_amount",
                                                    placeholder: " ",
                                                    value: el.ev_amount || '',
                                                    className: "form-input",
                                                    disabled:(el.id)?true:false
                                                    }}
                                                    onChange={(event:any) => { handleDateAmountChange("ev_amount",el.rowId, event.target.value) }}
                                                />    
                                            
                                                <label data-label="Approved Evaluation Amount" className="form-label"></label>
                                            </div>
                                        </fieldset>     

                                        <fieldset className="col-md-3">
                                        {
                                            (el.img_name)? 
                                            (el.id)?
                                                <div className='attech-repair-doc attech-doc-view' onClick={()=>{showImage(el.url,el.img_name)}}>
                                                    <p>{el.img_name}</p>
                                                </div>: 
                                                <div className='attech-repair-doc attech-doc-view'>
                                                    <i className='ic-attach_file' onClick={()=>{showEvaluationLetterPopup(el.ev_type,el.rowId)}}></i>
                                                    <p>{el.img_name}</p>
                                                    <i className='ic-cancel' onClick={()=>{removeImage(el.ev_type,el.rowId)}}></i>
                                                </div>: 
                                            <button className='btn-line' onClick={()=>{showEvaluationLetterPopup(el.ev_type,el.rowId)}}>
                                                <i className='ic-attach_file'></i>
                                                Attach Docs
                                            </button>
                                        }
                                        {errorMsg && errorMsg['img_name_'+el.rowId]? <span className="error-txt">{errorMsg['img_name_'+el.rowId]}</span> : null}
                                        </fieldset> 
                                    </div>
                                )
                            }
                            {
                                showAddEvaluation
                                    ?
                                    <fieldset className={(addBtnEvalStatus) ? `col-md-4 display-block` : `col-md-4 display-none`}>
                                        {
                                            (showContinue) ?
                                                <div className='add-repait-doc-txt' onClick={(e: any) => { addEvaluationSlab(2) }}>
                                                    <i className='ic-add'></i>
                                                    Add Updated Evaluation
                                                </div>
                                                : undefined
                                        }
                                    </fieldset>
                                    : undefined
                            }
                        </div>
                    </div>

                    <div className="btn-continue">
                    {
                        (showContinue && HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_claim_application'], accessType: 'edit', assignUserId: claimState?.claimDetails?.assign_user_id }) && (claimState.claimDetails.status_id !== STATUS_SUB_STATUS['status']['mark-as-lost'])) ?
                        <button type="button" className="btn-primary" onClick={handleSave}>Continue</button>:
                        <button type="button" className="btn-primary" disabled>Continue</button>
                    }
                       
                    </div>
                </div>
            </div>




            <div className="model-outer Evaluation-Letter-popup">
                <Modal show={evaluationLetter} handleClose={hideEvaluationLetterPopup} >
                    <div className="modal-header">
                        <h2>{evaluationHeading}</h2>
                    </div>
                    <div className="modal-body" >
                        <div className='upload-file-outer'>
                            <FileUpload setImageValue={uploadImage} uploadReset={uploadReset} setError={setEmptyErr} BlankFlage = {BlankFlage}/>
                        </div>
                    </div>
                    <div className='modal-footer'>
                    </div>
                </Modal>
                
            </div>
        </div>
    )

}


const Modal = (props: any) => {
    let { handleClose, show, children } = props
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};