import React, { useCallback, useEffect, useState } from "react";
import ReactSelect from "../../../common/ReactSelect";
import { ExpiryEffectivityDate } from "@component/DatePicker";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import CustomSearch from "@common/CustomSearch";
import { EFFECTIVITY_EXPIRY_INTERVAL, FIELD_TYPE, INS_TYPE_COMPREHENSIVE, VEHICLE_TYPE_COMMERCIAL, VEHICLE_USAGE_COMMERCIAL } from "@src/config/constant";
import moment from 'moment';
import { toast } from "react-toastify";

export default function DynamicFormFields(props: any) {

    let { fetchAllInsurance, paginationData } = props
    const [refresh, setRefresh] = useState(0);
    const [minExpiryDate, setMinExpiryDate] = useState(moment().toDate());
    const [selectedCategory, setSelectedCategory] = useState(1);

    const CLAIM_TYPE_THIRD_PARTY = 3;

    const commonState: any = useSelector((state: any) => state.common)
    const claimState: any = useSelector((state: any) => state.claim)
    
    let mmvData = (commonState.mmvList) ? commonState.mmvList : []

    const getFieldItem = (key: any) => {
        let filterItem = props?.fields?.find((item: any) => item.name === key)
        return filterItem
    }

    const getOptionList = (fieldItem: any) => {
        let mmvDataRecord: any = mmvData[selectedCategory] || [];
        
        if (fieldItem.name === "make") {
            return mmvDataRecord?.make

        } else if (fieldItem.name === "model") {

            let makeItem = getFieldItem('make')
            if (makeItem) {
                let value = typeof makeItem?.value === 'object' ? makeItem?.value?.value : makeItem?.value;
                let modelList = mmvDataRecord?.model?.filter((modelItem: any) => modelItem.make === value || modelItem.value === 0)

                return modelList || []
            }

        } else if (fieldItem.name === 'version') {

            let modelItem = getFieldItem('model')
            if (modelItem) {
                let value = typeof modelItem?.value === 'object' ? modelItem?.value?.value : modelItem?.value;
                let modelList = mmvDataRecord?.version?.filter((versionItem: any) => versionItem.model == value || versionItem.value == 0)   // eslint-disable-line

                return modelList || []
            }

        } else if (fieldItem.name === 'vehicle_type_id') {

            let vehicleTypes = commonState?.common?.vehicle_type
            let onlyCommercialVehicleTypes = vehicleTypes?.filter((e: any) => e.vehicle_usage === VEHICLE_USAGE_COMMERCIAL) || [] //only commercial
            return onlyCommercialVehicleTypes
        } else {
            return fieldItem.options || []
        }
    }

    const getFieldValue = (fieldItem: any) => {
        return fieldItem.value || ''
    }

    const onValueSelect = (fieldItem: any, value: any) => {
        
        // if field is claim type then show toast error
        if(fieldItem?.name === 'claim_type' && props?.claimType?.length > 0){
            if (value?.value) {
                const matchedClaim = props?.claimType?.find(
                    (claim:any) => claim?.value === value?.value
                );
                
                if (matchedClaim && matchedClaim.error) {
                    toast.error(matchedClaim.error);
                    return;
                }
            }        
        }

        if (fieldItem && fieldItem.type === 'mobile') {
            if (value) {
                const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
                if (!regex.test(value)) {
                    return
                }
            }
        }

        let fieldName = fieldItem.name
        if (['effectivity_date', 'expiry_date'].includes(fieldName)) {

            if (fieldName === 'effectivity_date' && value) {
                const expiryDate = getFieldItem('expiry_date')
                const expiryDateValue = expiryDate?.value

                if (expiryDateValue && moment(expiryDateValue).startOf('day').diff(moment(value).startOf('day'), 'days') < EFFECTIVITY_EXPIRY_INTERVAL) {
                    expiryDate['value'] = null
                }
                setMinExpiryDate(moment(value).add(EFFECTIVITY_EXPIRY_INTERVAL, 'days').set({ h: 0, m: 0, s: 0 }).toDate())
            }
        }

        fieldItem['value'] = value
        fieldItem['error_message'] = '';

        resetChildDependecies(fieldItem)

        fetchValuesOnChange(fieldItem, value)
    }

    const fetchValuesOnChange = async (fieldItem: any, value?: any) => {
        if (props.callFrom === 'edit_claim' && fieldItem.name !== 'insurance_type') return

        switch (fieldItem.name) {
            case "insurance_type":
                fetch_mgf_year(fieldItem)
                break

            case "insurance_id":
                props?.fetchInsuranceForClaimById({ lead_id: value.value })
                break

            case "plate_number":
                const insuranceId = getFieldItem("insurance_id")?.value?.value || null
                debouncedSearchInsurance(value, insuranceId)
                break

            case "vehicle_type_id":
                let category = value?.category_id || fieldItem?.value?.category_id
                await props?.fetchMMV({ category: category })
                setSelectedCategory(category);
                break

            default:
                break
        }
    }

    const debouncedSearchInsurance = useCallback(
        debounce((value: any, insurance_id: any) => {
            props?.fetchInsuranceForClaimById({ plate_number: value, id: insurance_id });
        }, 1000), []
    );

    const isEnable = (fieldItem: any) => {
        let insuranceId = props.callFrom === 'add_claim' ? getFieldItem('insurance_id') : { value: { value: true } } //for edit make value true to disable
        if (insuranceId && insuranceId?.value?.value) {
            const disabledFields = ['name', 'make', 'make_name', 'model', 'model_name', 'version', 'version_name', 'policy_number', 'partner_id', 'region', 'mfg_year', 'effectivity_date', 'expiry_date', 'vehicle_type_id', 'engine_number', 'chassis_number']

            if (disabledFields.includes(fieldItem.name)) {
                if (props.callFrom === 'add_claim' && (
                    (fieldItem.value || fieldItem.value === 0) &&
                    (claimState?.insuranceData?.[fieldItem.name] || claimState?.insuranceData?.[fieldItem.name] === 0))) {
                    return false
                } else if (props.callFrom === 'edit_claim') {
                    return false
                }
            }
        }
        return fieldItem.is_enable
    }

    const isShowDynamicField = (field: any) => {
        if (field?.field_show) {
            const splitType = field?.field_show?.split('/')
            if (splitType?.length > 1) {
                const filterFieldItem = props?.fields?.find((each: any) => each.name === splitType[0])
                if (filterFieldItem?.value?.value == splitType[1] || filterFieldItem?.value == splitType[1]) {  // eslint-disable-line
                    return true;
                }
                else return false;
            }
        }

        return !!field?.['is_show']
    }

    const resetChildDependecies = (fieldItem: any) => {
        let childDependencies = fieldItem?.child_depedencies || [];

        if (fieldItem.name === 'vehicle_type_id') {
            childDependencies = [...childDependencies, 'make', 'model', 'version'];
        }

        // eslint-disable-next-line
        childDependencies.map((key: any) => {
            const fieldData = props?.fields?.find((fieldData: any) => fieldData.name === key)
            if (fieldData) {
                fieldData['error'] = ''
                fieldData['value'] = ''
            }
        })

        setRefresh(refresh + 1);
    }

    const fetch_mgf_year = async (fieldItem?: any) => {
        let mgf_year = (commonState?.common?.make_year) ? commonState.common.make_year : {}
        let makeYearField = getFieldItem('mfg_year')

        if (makeYearField) {
            makeYearField['options'] = (fieldItem.value.value === INS_TYPE_COMPREHENSIVE) ? mgf_year?.mgf_comprehensive : mgf_year?.mgf_ctpl
        }
    }

    const updateFormFields = async () => {
        let insuranceData = props?.callFrom === 'add_claim' ? claimState?.insuranceData : claimState?.claimDetails

        if (insuranceData && Object.keys(insuranceData || {}).length && props.fields?.length) {
            let vehicleType = insuranceData['vehicle_type_id']
            let category = commonState?.common?.vehicle_type?.find((e: any) => e.value === +vehicleType)?.category_id

            //if mmvdata dont exists in store, then call api
            if(!mmvData?.[category]?.make?.length) {
                const mmv = await props?.fetchMMV({ category: category })
    
                if (mmv?.make?.length) {
                    mmvData = mmv
                }
            }


            for (let field of props.fields) {
                if (insuranceData[field.name] || insuranceData[field.name] === 0) {
                    let fieldType = field.type
                    let value = insuranceData[field.name]

                    if (['sdd', 'dd'].includes(fieldType)) {
                        let options = (field.options?.length && !['make', 'model', 'version'].includes(field.name)) ? field.options : getOptionList(field)

                        if (options?.length) {
                            field.value = options?.find((e: any) => e.value == value) || "" //eslint-disable-line
                        }

                        //if mmv not found
                        if (['make', 'model', 'version'].includes(field.name)) {
                            if (!field.value) {
                                field.value = { value: insuranceData[`${field.name}_name`], label: insuranceData[`${field.name}_name`] }
                            }
                        }
                    } else {
                        field.value = value
                    }

                    field.error_message = ""

                    if (field.name !== 'plate_number' && field.name !== 'vehicle_type_id') fetchValuesOnChange(field)
                } else if (field.name !== 'insurance_id') {
                    field.value = typeof field.value === 'object' ? {} : ""
                }
            }
            setRefresh((prev: any) => (prev + 1))
        }
    }

    useEffect(() => {
        updateFormFields()
        //eslint-disable-next-line
    }, [claimState.insuranceData, claimState.claimDetails])

    return (
        <>
            {
                props?.fields?.map((fieldItem: any, index: number) => {
                    let inputType = 'text'
                    const isShowField = isShowDynamicField(fieldItem)
                    let optionsList = (fieldItem?.options?.length && !['make', 'model', 'version', 'vehicle_type_id'].includes(fieldItem.name)) ? fieldItem.options : getOptionList(fieldItem);

                    if(fieldItem?.name === "insurance_id"){
                        optionsList = claimState.insuranceLeads || []
                    }                    
                    let optionValue = typeof fieldItem.value === 'object' ? fieldItem.value : (fieldItem.value != 0 || fieldItem.value === 0) ? optionsList?.filter((option: any) => option.value == fieldItem.value)?.[0] : null;   // eslint-disable-line
                    
                    switch (fieldItem.type) {
                        case FIELD_TYPE.CURRENCY:
                        case FIELD_TYPE.TELEPHONE:
                        case FIELD_TYPE.SUM:
                        case FIELD_TYPE.TEXT_BOX:
                        case FIELD_TYPE.MOBILE_FIELD:
                            return (
                                isShowField &&
                                <fieldset className={"form-filed col-md-4"} key={index}>
                                    <div className="material">
                                        {
                                            isEnable(fieldItem) ?
                                                <input
                                                    maxLength={fieldItem.type === FIELD_TYPE.MOBILE_FIELD ? 10 : 1000}
                                                    disabled={!isEnable(fieldItem)}
                                                    type={inputType}
                                                    placeholder=" "
                                                    name={fieldItem.name}
                                                    className="form-input"
                                                    value={getFieldValue(fieldItem) || ''} onChange={(event) => { onValueSelect(fieldItem, event.target.value) }}
                                                />
                                                :
                                                <input
                                                    maxLength={fieldItem.type === FIELD_TYPE.MOBILE_FIELD ? 10 : 1000}
                                                    disabled={!isEnable(fieldItem)}
                                                    type={inputType}
                                                    placeholder=" "
                                                    name={fieldItem.name}
                                                    className="form-input"
                                                    value={getFieldValue(fieldItem) || ''}
                                                />
                                        }
                                        {
                                            <label data-label={fieldItem.label + (fieldItem.is_required ? '*' : (fieldItem?.conditional_manadatory ? '**' : '')) + (['third_party_name'].includes(fieldItem.name) && getFieldValue(getFieldItem('claim_type'))?.value === CLAIM_TYPE_THIRD_PARTY ? '*' : '')} className="form-label"></label>
                                        }

                                        {fieldItem?.error_message ? <span className="error-txt">{fieldItem.error_message}</span> : null}
                                    </div>
                                </fieldset >
                            )

                        case FIELD_TYPE.DATE_PICKER: {
                            return (
                                isShowField &&
                                <fieldset className={"form-filed date-picker-detail col-md-4"} key={index}>
                                    <div className={"material animation-effect" + (fieldItem.value ? ' active-label' : '')}>
                                        <ExpiryEffectivityDate
                                            onChange={(e: Date) => onValueSelect(fieldItem, e)}
                                            placeholder=""
                                            text={fieldItem.label + (fieldItem.is_required ? "*" : '')}
                                            insurancePolicyDate={fieldItem.value ? new Date(fieldItem.value) : undefined}
                                            minDate={fieldItem.name === 'expiry_date' ? new Date(minExpiryDate) : null}
                                            maxDate={undefined}
                                            flag={fieldItem.name}
                                            disabled={!isEnable(fieldItem)}
                                        />
                                        {fieldItem?.error_message ? <span className="error-txt">{fieldItem.error_message}</span> : null}
                                    </div>
                                </fieldset>
                            )
                        }

                        case FIELD_TYPE.TEXT_AREA:
                            return (
                                <fieldset className="form-filed col-md-4" key={index}>
                                    <div className={"material"}>
                                        <textarea id="description" placeholder=" " name="notes" className="form-input" value={fieldItem.value} onChange={(e) => onValueSelect(fieldItem, e.target.value)} />
                                        <label data-label={fieldItem.label} className="form-label"></label>
                                    </div>
                                </fieldset>
                            )

                        case FIELD_TYPE.SEARCH:
                            return (
                                <React.Fragment key={index}>
                                    <CustomSearch
                                        // data={optionsList}
                                        handleDataClick={(value: any) => onValueSelect(getFieldItem('insurance_id'), value)}
                                        searchFields={['plate_number', 'lead_id', 'policy_number', 'customer_name']}
                                        placeholder={"Search By Insurance ID"} 
                                        apiCall={fetchAllInsurance}
                                        // paginationData={paginationData}
                                        />
                                </React.Fragment>
                            )

                        default: {
                            return (

                                isShowField &&
                                <React.Fragment key={index} >
                                    {
                                        isEnable(fieldItem) ?
                                            <ReactSelect
                                                options={optionsList}
                                                value={optionValue}
                                                handleChange={(e: any, fieldKey: string) => onValueSelect(fieldItem, e)}
                                                placeholder={fieldItem.label + (fieldItem.is_required ? '*' : '')}
                                                fieldKey=""
                                                fieldItemName={fieldItem.name}
                                                isDisabled={!isEnable(fieldItem)}
                                                errorField={fieldItem?.error_message}
                                                fieldSetClassName={"form-filed col-md-4"}
                                            />
                                            :
                                            <ReactSelect
                                                options={optionsList}
                                                value={optionValue}
                                                placeholder={fieldItem.label + (fieldItem.is_required ? '*' : '')}
                                                fieldKey=""
                                                isDisabled={!isEnable(fieldItem)}
                                                errorField={fieldItem?.error_message}
                                                fieldSetClassName={"form-filed col-md-4"}
                                            />
                                    }

                                </React.Fragment>
                            )
                        }
                    }
                })
            }
        </>
    )
}