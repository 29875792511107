

import _ from "lodash";

export const API_URL = {
  GETWAY_API: process.env.REACT_APP_GETWAY_API,
  COMMON_GATEWAY_API: "https://int-gcloud-stage.oto.com/",
  API_KEY: "claimbox-47caltda-94c2-47da-9MPGT-9aff257b0d5"
};

export const APIV = 2;

export const INCENTIVE_IMAGE_FOLDER= "U2FsdGVkX1/7ai0FJgAUaUT+YcESDZTJH7t04gZuLUw=";
export const SFA_IMAGE_FOLDER="sfa_docs";
export const AGENT_ROLE_ID = 53; 
export const ARO_ROLE_ID   = 57; 
export const BRO_ROLE_ID   = 54;
export const ARCO_ROLE_ID  = 59;

export const REDIRECT_URLS = {
  "beta" : "http://sso-beta.carmudi.com.ph/google?redirect=",
	"preprod" : "https://pre-sso.carmudi.com.ph/google?redirect=",
	"prod" : "https://sso.carmudi.com.ph/google?redirect=",
	"local" : "http://sso-beta.carmudi.com.ph/google?redirect="
}

export const REDIRECT_URL = REDIRECT_URLS[process.env.REACT_APP_ENV]

export const APP_TYPE_STANDALONE = 2;
export const APP_TYPE_WITH_UCF = 1;
export const APP_TYPE_RENEWAL = 3;
export const APP_TYPE_AGENCY = 4;

export const ROLE_FEATURES = {
    "dashboard_page": 1,
    "email_template_management": 2,
    "user_role_management": 3,
    "region_management": 4,
    "claim_management": 5,
    "create_claim_application": 6,
    "update_claim_application": 7
}

export const VEHICLE_USAGE_COMMERCIAL = 2;

export const CLAIM_TYPE_THIRD_PARTY = 3;

export const FIELD_TYPE = {
  TEXT_BOX: 'tb',
  DROP_DOWN: 'dd',
  DROPDOWN_INPUT_FIELD: 'ddtb',
  SEARCHABLE_DROP_DOWN: 'sdd',
  READ_ONLY_TEXT_BOX: 'ro',
  DATE_PICKER: 'date',
  MOBILE_FIELD: 'mobile',
  STICKY_FIELD: "sticky",
  EMAIL: 'email',
  MARKET_MRP: 'market_mrp',
  CITY: 'city',
  STATE: 'state',
  LOCALITY: 'locality',
  RADIO_BOX: 'radio',
  MRP_UPPING: 'mrp_upping',
  CURRENCY: 'currency',
  FUTURE_DATE: 'future_date',
  TELEPHONE: 'telephone',
  CHECK_BOX: 'checkbox',
  SUM: 'sum',
  TEXT_AREA: 'ta',
  MULTI_DROP_DOWN: 'mdd',
  SEARCH: 'search'
}

export const INS_TYPE_COMPREHENSIVE = 1;

export const DETAIL_NAV_MAPPING = ['claim-details', 'document-upload', 'send-insurance', 'cnc-received', 'evaluation-received', 'loa-received', 'claim-closing', 'transfer-proof']

export const STATUS_SUB_STATUS = {
  status: {
    'application': 1,
    'document-uploaded': 2,
    'send-to-insurance-broker': 3,
    'cnc-received': 4,
    'evaluation-received': 5,
    'loa-received': 6,
    'claims-closing': 7,
    'transfer-proof': 8,
    'mark-as-lost': 9
  }
}

export const WHATSAPP_WEB_URL = "https://web.whatsapp.com/send?text=";
export const DOC_UPLOAD_VALIDATION = {"minSize": 25000, "maxSize": 5000000}

export const EFFECTIVITY_EXPIRY_INTERVAL = 60;

export const DOCUMENT_STATUS = { 0: 'Unsubmitted', 1: 'Incomplete', 2: 'Uploaded' }
export const WHATSAPP = 2
export const EMAIL = 1

export const CUSTOMER_UPLOAD_FIELDS = [{key: 'customer_name', name: ''}, {key: 'insurance_id', name: 'Insurance No'}, { key: 'partner_name', name: ''}, { key: 'claim_id', name: 'Claim ID'}]
export const REGEX_DECIMAL_VALIDATION = /^[0-9]*(\.[0-9]{0,2})?$/;

export const SEND_TO_CUST_DOCS = 1
export const SEND_TO_INS_PARTNER = 2
export const SEND_TO_CUST_CLAIM_CLOSING = 3

export const COMPREHENSIVE = 1
export const ACCREDITED = [
  { value:1, label: 'Yes' },
  { value:0, label: 'No' },

];

export const MARK_AS_LOST = 9
export const IMG_SOURCE_CUSTOMER = 2;
export const LOA_FIELDS=['claim_number','approve_ref_no'];
export const LOA_AMT_FIELDS=["approve_final_amt","dep_amt_applied","deduc_amt_applied"];
export const CLAIM_TYPE_CNC = 2;
export const DOC_VIDEO_RECORDINGS = 6;
export const VIDEO_UPLOAD_VALIDATION = {"minSize": 25000, "maxSize": 1000000000}
export const TRANSFER_PROOF = 8;
export const CNC_RECEIVED = 4;
export const VIDEO_UPLOAD_LIMIT_ACCIDENT_VEHICLE_VIDEOS = 2;
export const ALLOWED_VIDEO_DURATION = 90;
export const VEHICLE_TYPE_COMMERCIAL = [3, 4, 5] //trucks
export const ACCIDENT_VEHICLE_PHOTOS = 4;
export const CURRENCY_SYMBOL = '₱';