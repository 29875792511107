import { useApolloClient } from "@apollo/client"
import { getInsuranceForClaim } from "@store/action/allAction"
import { setInsuranceLeads } from "@store/slices/claim"
import { debounce } from "lodash"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

export default function CustomSearch(props: any) {
    const [show, setShow] = useState(false)
    const [value, setValue] = useState("")
    const [loopData, setLoopData] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [isScrolling, setIsScrolling] = useState(0)
    const [insuranceListPaginationObj, setInsuranceListPaginationObj] = useState<any>({})
    const observerRef = useRef(null)
    const client = useApolloClient();
    const claimState = useSelector((state: any) => state.claim)
    const dispatch: any = useDispatch()


    const handleClick = () => {
        dispatch(setInsuranceLeads([]))
        if(loopData?.length && value){
            setShow(true)

        }
    }

    const fetchInsuranceForClaim = async (apiType: string, query = '') => {
        setLoading(true)
        if (apiType === 'new') {
            setLoopData([])
        }
        // const data: any = await dispatch(getInsuranceForClaim(client, { page_no:  1, query }))
        const data: any = await dispatch(getInsuranceForClaim(client, { page_no: (insuranceListPaginationObj?.cur_page + 1) || 1, query }))

        //if apitype is new then use updated data else merge previously
        //fetched data
        let combinedData = [...data?.data]
        if (apiType === 'old') combinedData = [...loopData, ...combinedData]
        dispatch(setInsuranceLeads(combinedData))
        setLoopData(combinedData)
        setShow(false)
        if(combinedData?.length){
            setShow(true)
        }

        setInsuranceListPaginationObj(data?.pagination || {})

        if (insuranceListPaginationObj || query) {
            setLoading(false)
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    if ((insuranceListPaginationObj?.next_page)) {
                        // props.apiCall('old')
                        fetchInsuranceForClaim("old", value)
                    } else setLoading(false)
                }
            },
            { threshold: 1.0 } // Trigger when fully visible
        );

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [isScrolling]);

    // useEffect(() => {
    //     if (props?.data?.length) {
    //         setLoopData(props.data)
    //     }
    // }, [props?.data])

    const debouncedSearch = useCallback(
        debounce((searchKeyword: string) => {
            fetchInsuranceForClaim('new', searchKeyword)
            setValue(searchKeyword)

        }, 1000), []
    );

    const handleSearch = (searchKeyword: string) => {
        debouncedSearch(searchKeyword);
        setValue(searchKeyword);
    }

    return (
        <React.Fragment key={'custom-search'}>
            <div className="col-md-4">
                <div className="add-insurnace-search-outer">
                    <div className="search-bx">
                        <div className="material">
                            <input type="text" autoComplete="off" placeholder=" " name="search_text" className="form-input" title="Search Text" aria-label="Search text" onClick={() => handleClick()} onBlur={() => {
                                setTimeout(() => {
                                    setShow(false)
                                }, 200)
                            }} onChange={(e) => handleSearch(e.target.value)}
                                value={value} />
                            <i className="ic-search"></i>
                            <label data-label={props?.placeholder || ""} className="form-label search-label"></label>
                        </div>
                        {
                            show && loopData?.length >=0
                                ?
                                <div className="searhc-list-outer" onScroll={() => {
                                    setIsScrolling(isScrolling + 1)
                                }}>
                                    <ul>
                                        {
                                            loopData?.map((element: any, idx: number) => {
                                                return (
                                                    <li key={idx + "_" + element.lead_id} onClick={() => {
                                                        setValue(element.lead_id)
                                                        props?.handleDataClick(element)
                                                    }}>
                                                        <label>Insurance ID {element.lead_id}</label>
                                                        <div className="insurance-detail-list">
                                                            <span className="search-span-one" title={element.customer_name}>{element.customer_name}</span>
                                                            <span className="search-span-two" title={element.plate_number}>{element.plate_number}</span>
                                                            <span className="search-span-three" title={element.expiry_date}>Expiry: {element.expiry_date}</span>
                                                            <span className="search-span-four" title={element.policy_number}>Policy No: {element.policy_number}</span>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    {
                                        (loopData?.length > 0 && insuranceListPaginationObj?.next_page)
                                        && <div ref={observerRef} style={{ padding: "8px", textAlign: "center", background: "#f9f9f9" }}>
                                            {loading ? "Loading..." : "Scroll down to load more"}
                                        </div>
                                    }
                                </div>
                                : undefined
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}