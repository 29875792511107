import { CLAIM_TYPE_THIRD_PARTY } from "@src/config/constant"

export function updateFormFields(fields: any, claimState: any, masterData: any) {
    fields = JSON.parse(JSON.stringify(fields))
    let mappedFields: any = {
        vehicle_type_id: 'vehicle_type',
        partner_id: 'partner_list',
        region: 'region_list'
    }

    for (let field of fields) {
        let fieldName: any = mappedFields?.[field.name] || field.name

        if (fieldName === 'insurance_id' && claimState?.insuranceLeads?.length) {
            field['options'] = claimState?.insuranceLeads
        }

        else if (masterData?.[fieldName]) {
            field['options'] = masterData[fieldName]
        }
    }

    return fields
}

export const isShowDynamicField = (field: any, formFields: any) => {
    if (field?.field_show) {
        const splitType = field?.field_show?.split('/')
        if (splitType?.length > 1) {
            const filterFieldItem = formFields?.find((each: any) => each.name === splitType[0])
            if (filterFieldItem?.value?.value == splitType[1] || filterFieldItem?.value == splitType[1]) {  // eslint-disable-line
                return true;
            }
            else return false;
        }
    }
    return !!field?.['is_show']
}

export function validateInputFields(formFields: any) {
    let error = false

    for (let field of formFields) {
        if (field.is_required && !field.value && isShowDynamicField(field, formFields)) {
            error = true
            field['error_message'] = field['error_msg']
        }

        if (['mobile', 'third_party_mobile'].includes(field.name) && field.value) {
            let isValidMobile = (/^[9]\d{9}$/).test(field.value)
            if (!isValidMobile) {
                field['error_message'] = `${field.name === 'mobile' ? 'Mobile number' : 'Third Party Mobile'} should start with 9 and 10 digit number`
                error = true
            }
        }

        if (field.name === 'name' && field.value) {
            let isValidName = field.value.trim();
            if (!isValidName) {
                field['error_message'] = 'Please enter valid name'
                error = true
            }
        }

        if (field.name === 'email' && field.value) {
            // eslint-disable-next-line
            const validEmailRegex = /^[^@]+@[^@]+\.[^@.]{2,5}$/;
            if (!validEmailRegex.test(field.value)) {
                field['error_message'] = 'Please enter valid email'
                error = true
            }
        }

        if (field.name === 'plate_number' && field.value) {
            let alphanumericPattern = /^[a-z0-9]+$/i;
            if (!alphanumericPattern.test(field.value)) {
                field['error_message'] = 'Plate number should be alphanumeric'
                error = true
            }
        }

        if (['third_party_name'].includes(field.name) && !field.value) {
            let claimType = formFields.find((e: any) => e.name === 'claim_type')
            if (claimType?.value?.value === CLAIM_TYPE_THIRD_PARTY) {
                error = true
                field['error_message'] = field['error_msg']
            }
        }
    }

    return error
}

export const createClaimFields = (formFields: any, type?: string, insuranceData?: any): { claimDetails: any, vehicleDetails: any, customerDetails: any } => {
    const claimDetailKeys = ['insurance_id', 'insurance_type', 'partner_id', 'policy_number', 'claim_type', 'third_party_name', 'third_party_mobile', 'remarks', 'effectivity_date', 'expiry_date']
    const vehicleDetailsKeys = ['make_id', 'make_name', 'model_id', 'model_name', 'version_id', 'version_name', 'plate_number', 'engine_number', 'chassis_number', 'mfg_year', 'vehicle_type_id']
    const customerDetailKeys = ['name', 'mobile', 'email', 'region']

    let claimDetails: any = {}
    let vehicleDetails: any = {}
    let customerDetails: any = {}

    for (let field of formFields) {
        let key = field?.name
        let value = (typeof field.value === 'object' && !['effectivity_date', 'expiry_date'].includes(field.name)) ? field.value.value : field.value

        if (['make', 'model', 'version', 'make_name', 'model_name', 'version_name'].includes(key)) {
            let updatedKey = !key.includes('name') ? key + '_id' : key
            let trimmedKey = key.replace('_name', '')
            vehicleDetails[updatedKey] = (value || value === 0) ? value : formFields?.find((e: any) => e.name === trimmedKey)?.value?.label

            //case when make model or variant is hard coded, get the 
            //value from insurance data
            if (['make', 'model', 'version'].includes(key) && typeof value === 'string' && insuranceData?.[key]) {
                vehicleDetails[updatedKey] = insuranceData[key]
            }

            continue
        }

        if(key === 'insurance_type'){
            claimDetails['insurance_type_name'] = field.value.label
        }

        if(key === 'partner_id'){
            claimDetails['partner_name'] = field.value.label
        }

        if (!value && type !== 'update') continue

        if (claimDetailKeys.includes(key)) {
            claimDetails[key] = value
        } else if (vehicleDetailsKeys.includes(key)) {
            vehicleDetails[key] = value
        } else if (customerDetailKeys.includes(key)) {
            customerDetails[key] = value
        }

        if(key === 'claim_type'){
            claimDetails['claim_type_name'] = field.value.label;
        }
    }

    return { claimDetails, vehicleDetails, customerDetails }
}



export function validateEvaluationFields(formFields: any, detailEval: any) {
    let error = false;
    let error_msg: any = {};
    if (Object.keys(formFields).length) {

        if (!formFields.hasOwnProperty("cash_settlement") || formFields.cash_settlement === '') {
            error_msg['cash_settlement'] = 'Please select cash settlement'
            error = true
        } else {
            delete error_msg['cash_settlement'];
        }
        if (formFields.shop_id !== 0 && (!formFields.hasOwnProperty("shop_id") || formFields.shop_id == '')) {
            error_msg['shop_id'] = 'Please select Repair Shop'
            error = true
        } else {
            delete error_msg['shop_id'];
        }

        if (!formFields.hasOwnProperty("region_id") || formFields.region_id == '') {
            error_msg['region_id'] = 'Please select Repair Shop Region'
            error = true
        } else {
            delete error_msg['region_id'];
        }

        // if(!formFields.hasOwnProperty("is_shop_accredited") || formFields.is_shop_accredited=='' ){
        //     error_msg['is_shop_accredited'] = 'Please select shop accredited'
        //         error = true
        // }else{
        //     delete error_msg['is_shop_accredited'];
        // }

        if (detailEval.length) {
            let filterData: any = detailEval.filter((el: any) => !el.id);
            if (filterData.length) {
                let repair_est_Data = filterData.filter((el: any) => el.ev_type === 1);
                let approved_Data = filterData.filter((el: any) => el.ev_type === 2);
                if (repair_est_Data.length) {
                    for (let data of repair_est_Data) {
                        if (!data?.ev_amount) {
                            error_msg['ev_amount_' + data.rowId] = 'Please enter estimated amount';
                            error = true
                        } else {
                            delete error_msg['ev_amount_' + data.rowId]
                        }

                        if (data.img_name === null) {
                            error_msg['img_name_' + data.rowId] = 'Please attach file';
                            error = true
                        } else {
                            delete error_msg['img_name_' + data.rowId]
                        }
                    }
                }

                if (approved_Data.length) {
                    for (let data of approved_Data) {
                        if (data.ev_datetime === null) {
                            error_msg['ev_datetime_' + data.rowId] = 'Please select date and time';
                            error = true
                        } else {
                            delete error_msg['ev_datetime_' + data.rowId]
                        }

                        if (data.img_name === null) {
                            error_msg['img_name_' + data.rowId] = 'Please attach file';
                            error = true
                        } else {
                            delete error_msg['img_name_' + data.rowId]
                        }
                    }
                }

            }
        }

    }

    return { error, error_msg };

}

export function validateLoaFields(fieldArr: any) {
    let error = false;
    let error_msg: any = {};
    if (fieldArr.length) {
        for (const [index, data] of fieldArr.entries()) {
            if (data.accident_date === null) {
                error_msg['accident_date_' + index] = 'Please select accident date';
                error = true
            } else {
                delete error_msg['accident_date_' + index]
            }
            if (!data?.claim_number) {
                error_msg['claim_number_' + index] = 'Please enter claim number';
                error = true
            } else {
                delete error_msg['claim_number_' + index]
            }
            if (data.approval_date === null) {
                error_msg['approval_date_' + index] = 'Please select approval date';
                error = true
            } else {
                delete error_msg['approval_date_' + index]
            }
            if (!data?.approve_ref_no) {
                error_msg['approve_ref_no_' + index] = 'Please enter approval letter reference no';
                error = true
            } else {
                delete error_msg['approve_ref_no_' + index]
            }
            if (!data?.approve_final_amt) {
                error_msg['approve_final_amt_' + index] = 'Please enter approved claim amount';
                error = true
            } else {
                delete error_msg['approve_final_amt_' + index]
            }
            if (!data?.dep_amt_applied) {
                error_msg['dep_amt_applied_' + index] = 'Please enter depreciation amount applied';
                error = true
            } else {
                delete error_msg['dep_amt_applied_' + index]
            }
            if (!data?.deduc_amt_applied) {
                error_msg['deduc_amt_applied_' + index] = 'Please enter deductible amount applied';
                error = true
            } else {
                delete error_msg['deduc_amt_applied_' + index]
            }
            if (data.net_amt_approved && data.net_amt_approved <= 0) {
                error_msg['net_amt_approved_' + index] = 'Net amount should be greater than zero';
                error = true
            } else {
                delete error_msg['net_amt_approved_' + index]
            }
            // if (data.remark === null) {
            //     error_msg['remark_' + index] = 'Please enter remarks';
            //     error = true
            // } else {
            //     delete error_msg['remark_' + index]
            // }

            if (data.img_name === null) {
                error_msg['img_name_' + index] = 'Please attach file';
                error = true
            } else {
                delete error_msg['img_name_' + index]
            }
        }
    }
    return { error, error_msg };

}