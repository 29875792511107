import MasterService from './MasterService';


export const UserService = {
    ...MasterService,
     
    VerifyOtp(params){
        return this.post('/userAdminAuth/adminlogin',params)
    },
    createOtp(params){
        return this.post('/userAdminAuth/verifyLogin',params)
    },
    loginCaptcha(){
        return this.get('/userAdminAuth/getCaptchaImage')
    },  
    VerifyEmail(params){
        return this.post('/userAdminAuth/adminDirectlogin',params)
    },
    creatLoginOtp(params){
        return this.post('/userAdminAuth/verifyGoogleLogin',params)
    },
    getAllUsers(params) {
        return this.post('/user/userList', params)
    },

}