import { useEffect, useState } from 'react';
// import { Button, Dropdown, Tab, Tabs, Nav } from 'react-bootstrap';
import DocNotification from "../DocumentNotifications";
import ClaimListFilter from "./ClaimListFilter";
import ClaimTable from "./ClaimListTable";
import CreateClaim from './create/index';
// @ts-ignore
import { useApolloClient } from '@apollo/client';
import { ROLE_FEATURES } from '@src/config/constant';
import * as HELPER from '@src/config/helper';
import { exportGetList } from "@src/store/slices/claim";
import { getFormConfig, getMMVList, getRegionListing } from '@store/action/allAction';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import FullPageLoader from '../../common/FullPageLoader';
import { GET_CLAIM_LIST_DATA } from "../../services/claimdata.gql";


const ClaimLeadList = (props: any) => {

    const [addApplication, setAddApplication] = useState(false);
    const [isDownloadReady, setIsDownloadReady] = useState(false);
    let [claimList, setClaimList] = useState([]);
    let [loading, setLoading] = useState(false);
    let [paginationData, setPaginationData] = useState({})
    const [selectedFilters, setSelectedFilters] = useState<any>({});
    const [btnEnable, setBtnEnable] = useState(false);
    const [showSelectedAssignSave, setShowSelectedAssignSave] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams()
    const client = useApolloClient();
    const dispatch: any = useDispatch();
    const commonState: any = useSelector((state: any) => state.common)


    const showAddApplicationPopup = () => {
        setAddApplication(true);
        document.body.classList.add("overflow-hidden");
    };


    const hideAddApplicationPopup = () => {
        setAddApplication(false);
        document.body.classList.remove("overflow-hidden");
    };

    const [csvData, setCSVData] = useState([]);
    const exportHeaders = [
        { label: "Claim ID", key: "claim" },
        { label: "Vehicle Details", key: "vehicle_details" },
        { label: "Insurance Provider", key: "insurance_provider" },
        { label: "Insurance Type", key: "insurance_type" },
        { label: "Status", key: "status" },
        { label: "Policy Number", key: "polcy_number" },
        { label: "Customer Name", key: "customer_name" }
    ];




    useEffect(() => {
        if (isDownloadReady) {
            document?.getElementById("csvDownloadLink")?.click();
            setIsDownloadReady(false)
        }
    }, [isDownloadReady]) //as soon as our csv data gets ready, export data by click



    const getClaimList = async (filters: any, type?: any) => {
        setLoading(true);
        let updatedFilters: any = {}
        if (type && type === 'reset') {
            updatedFilters = {
                claim_input: {
                    ...filters
                }
            }
        } else {
            // let updatedFilters = {}
            updatedFilters = {
                claim_input: {
                    ...filters,
                    ...selectedFilters,
                    page_number: filters.page_number || 1,
                    searchKeyword: filters.searchKeyword

                }
            };

            if(!updatedFilters?.claim_input?.searchKeyword?.trim()) delete updatedFilters?.claim_input?.searchKeyword;
        }


        setSelectedFilters(updatedFilters.claim_input)
        const result = await executeGraphQLQuery(GET_CLAIM_LIST_DATA(), client, updatedFilters)
        if (result?.data?.getAlClaimList?.status === 200) {
            setClaimList(result?.data?.getAlClaimList.data || []);
            setPaginationData(result?.data?.getAlClaimList?.pagination ?? {})
            setLoading(false);
            setBtnEnable(false)

            if ((Object.keys(updatedFilters.claim_input).length > 1) && type !== 'reset' && result?.data?.getAlClaimList?.data?.length) {
                setBtnEnable(true)
            }
            //setBtnEnable(true)
            setShowSelectedAssignSave(0)
        }
        else {
            setShowSelectedAssignSave(0)
            setLoading(false);
        }

    }

    useEffect(() => {
        // setLoading(true);
        const statusIds = searchParams.get('claim_status')
        const searchText = searchParams.get('searchKeyword')

        let filters: any = {
            page_number: 1
        }
        //when dashboard count click
        if (statusIds?.length) {
            filters = { ...filters, claim_status: JSON.parse(statusIds) }
            const updatedParams: any = searchParams.delete('claim_status')
            setSearchParams(updatedParams)
        }

        if (searchText?.length) {
            filters = { ...filters, searchKeyword: searchText }
            const updatedParams: any = searchParams.delete('searchKeyword')
            setSearchParams(updatedParams)
        }

        getClaimList(filters);

        setPaginationData({});

        fetchMMVList();

        fetchRegionListing();

        fetchFormFields();

        // eslint-disable-next-line
    }, []);


    const handleChange = (selectedOption: any, key: any) => {

        let updatedFilters = selectedFilters;

        if (key === "searchKeyword") {
            updatedFilters = { ...updatedFilters, searchKeyword: selectedOption.target.value || undefined };
        }
        else {
            updatedFilters = { ...updatedFilters, [key]: selectedOption.value };
        }
        setSelectedFilters(updatedFilters);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {

            let searchParams: any = {
                page_number: 1,
                // enableKey: true,
            }
            if (selectedFilters?.searchKeyword?.trim()) {
                searchParams = {
                    ...searchParams,
                    searchKeyword: selectedFilters.searchKeyword
                }
            }
            getClaimList(searchParams);


        }
    }

    const onInputChange = (event: any) => {
        const searchText = event.target.value;
        const trimmedSearchText = searchText?.trim();
        // if(trimmedSearchText){

        setSelectedFilters({
            ...selectedFilters,
            searchKeyword: trimmedSearchText,
        });
        // }
    }

    const ExportClaimData = () => {
        const { page_number, ...filtersWithoutPageNumber } = selectedFilters;

        setLoading(true)
        let updatedFilters = {
            claim_input: {
                ...filtersWithoutPageNumber
            }
        };

        exportGetList(client, updatedFilters)
            .then((res: any) => {
                if (res?.data?.status === 200) {
                    toast.success(res?.data?.message);
                    setLoading(false)
                } else {
                    toast.error("Data not Found");
                    setLoading(false)
                }
            })
            .catch((error: Error) => {
                setLoading(false)
            });
    }

    const fetchMMVList = () => {
        if (!Object.keys(commonState?.mmvList).length) {
            dispatch(getMMVList(client, { params: {category: 1} }));
        }
    }

    const fetchRegionListing = () => {
        if (!Object.keys(commonState?.regionList).length) {
            dispatch(getRegionListing(client));
        }
    }

    const fetchFormFields = () => {
        if(!commonState?.formFields?.length){
            dispatch(getFormConfig(client));
        }
    }

    return (
        <div className='lead-list-outer container-fluid d-flex'>
            <FullPageLoader show={loading} />
            <div className='list-filter-notification'>
                <div className='filter-outer'>
                    <h2>Search By Filters</h2>
                    <ClaimListFilter setClaimList={setClaimList} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} setBtnEnable={setBtnEnable} setLoading={setLoading} setPaginationData={setPaginationData} getClaimList={getClaimList} />
                </div>

                <div className='claim-notification-outer'>
                    <h2>Document Notifications</h2>
                    <div className='notification-list-content'>
                        <DocNotification />
                    </div>
                </div>
            </div>

            <div className='claim-list-rigt-sec'>
                <div className="top-heading">
                    <h1>Insurance Claims</h1>
                </div>
                <div className='insurance-claim-list-outer'>
                    <div className='search-panel-btn'>
                        <div className="search-bx">
                            <div className="material" title="Search By Claim ID, Policy Number, Customer Name, Insurance ID">
                                <input type="text"
                                    placeholder="Search By Claim ID, Policy Number, Customer Name, Insurance ID"
                                    name="search_text"
                                    className="form-input"
                                    title="Search By Claim ID, Policy Number, Customer Name, Insurance ID"
                                    aria-label="Search text"
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => { onInputChange(e) }}
                                    value={selectedFilters?.['searchKeyword']}
                                />
                                <i className="ic-search"></i>
                            </div>
                        </div>
                        <div className='btn-right d-flex'>
                            {
                                <button className="btn-line  m-sm-l"
                                    disabled={!btnEnable}
                                    onClick={() => {
                                        ExportClaimData()
                                    }}>
                                    <i className="ic-export"></i>
                                    Export Data
                                </button>
                            }
                            {
                                HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['create_claim_application'], accessType: 'edit' }) &&

                                <button className="btn-primary m-sm-l" onClick={showAddApplicationPopup}> <i className="ic-add"></i>Add Application</button>
                            }
                        </div>

                    </div>
                    <ClaimTable claimList={claimList} paginationData={paginationData} getClaimList={getClaimList} setClaimList={setClaimList} setShowSelectedAssignSave={setShowSelectedAssignSave} showSelectedAssignSave={showSelectedAssignSave} selectedFilters={selectedFilters} setLoading={setLoading} />
                </div>
            </div>

            {addApplication && (
                <CreateClaim
                    showAddApplicationPopup={addApplication}
                    hideAddApplicationPopup={hideAddApplicationPopup}
                    getClaimList={getClaimList}
                />
            )}
        </div>
    );
};



export default ClaimLeadList;